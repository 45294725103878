import React, { useState } from "react"
import { graphql, navigate } from "gatsby"
import ReactMarkdown from "react-markdown/with-html"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Display,
  WorkBox,
  Wrapper,
  WorkBoxOverlay,
  WorkBoxText,
  WorkBack,
  Title,
  WorkHr,
  Body,
  Markdown,
} from "../styles"

const Works = ({ data }) => {
  const [list, setlist] = useState(false)
  const works = data.allStrapiWorks.edges.map(edge => edge.node)

  return (
    <Layout>
      <SEO title="Works" />
      <Display center>{list ? "List of Works" : "Works"}</Display>
      {list ? (
        <>
          <WorkBack onClick={() => setlist(false)}>back</WorkBack>
          <Wrapper>
            {works.map(work => (
              <div key={work.title}>
                <div style={{ padding: "10px" }}>
                  <Title>{work.title}</Title>
                  <Markdown>
                    <ReactMarkdown source={work.intro} escapeHtml={false} />
                  </Markdown>
                  {work.Box && (
                    <Body
                      style={{ cursor: "pointer", marginTop: "20px" }}
                      onClick={() => navigate(`/works/${work.title}/`)}
                    >
                      Go to work -&gt;
                    </Body>
                  )}
                </div>
                <WorkHr />
              </div>
            ))}
          </Wrapper>
        </>
      ) : (
        <Wrapper row wrap justifyEvenly>
          <WorkBox justifyCenter alignCenter onClick={() => setlist(true)}>
            <WorkBoxText center style={{ fontSize: "28px" }}>
              List of Works
            </WorkBoxText>
          </WorkBox>
          {works.map(
            work =>
              work.Box && (
                <WorkBox
                  key={work.title}
                  img={work.picture?.childImageSharp?.fluid?.src}
                  justifyEnd
                  onClick={() => navigate(`/works/${work.title}/`)}
                >
                  <WorkBoxOverlay>
                    <WorkBoxText center>{work.title}</WorkBoxText>
                  </WorkBoxOverlay>
                </WorkBox>
              )
          )}
        </Wrapper>
      )}
    </Layout>
  )
}

export default Works

export const worksQuery = graphql`
  query {
    allStrapiWorks(sort: { order: DESC, fields: Date }) {
      edges {
        node {
          title
          intro
          Box
          picture {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
